<template>
  <base-layout>
    <div class="score-wrapper scoreboard">
      <div class="title-wrap relative">
        <h3 class="page-titles">
          Team: {{ $store.state.TEAMANALYTICS.currentTeam ? $store.state.TEAMANALYTICS.currentTeam.name : '' }}
        </h3>
        <p>Here you can find information about your team as well as average data, etc.</p>
        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>
      <h5 class="font-medium mb-8">
        Scoreboard Team
      </h5>

      <div
        v-if="$store.state.TEAMS.teams[0]"
        class="flex flex-col flex-auto"
      >
        <div
          class="w-full h-full"
        >
          <router-view
            v-if="($store.state.TEAMS.teams && $route.name != 'Personality') || ($store.state.TEAMANALYTICS.currentCampaign && $route.name === 'Personality')"
            :key="$route.fullPath"
          />
          <div
            class="flex items-center justify-center w-full h-full"
            v-else
          >
            <button class="btn btn-lg btn-circle btn-ghost loading" />
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
import TheProfile from '@/components/TheProfile.vue'
import { mapGetters } from 'vuex'

export default {
  components: { BaseLayout, TheProfile },
  computed: {
    ...mapGetters({ teams: 'TEAMS/getTeams' }),
    showGoBackBtn () {
      return this.$route.name !== 'TeamAnalytics' && (!!this.$store.state.TEAMANALYTICS.currentTeam && !!this.$store.state.TEAMANALYTICS.currentTeam.Questionnaires && this.$store.state.TEAMANALYTICS.currentTeam.Questionnaires.length > 0)
    }
  },
  async created () {
    if (this.$route.params.page) {
      const parsed = parseInt(this.$route.params.page)
      if (!isNaN(parsed)) {
        this.$store.commit('TEAMS/SET_CURRENT_PAGE', parsed)
      }
    }
    await this.$store.dispatch('TEAMS/getTeams')

    if (this.$route.params.campaignId) {
      this.$store.dispatch('TEAMANALYTICS/setCurrentTeam', this.$route.params.campaignId)
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.campaignId) {
      this.$store.dispatch('TEAMANALYTICS/setCurrentTeam', to.params.campaignId)
    }
    next()
  }
}
</script>

<style lang="scss">
  @import '../Scoreboard/scoreboard.scss';
  </style>
